import React, { useEffect, useState, useCallback } from "react";
//import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
//import { compose, withProps, withHandlers } from "recompose";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import * as styleSheet from "./index.module.scss"

/*const Map = compose(
		withProps({
			googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" + process.env.GATSBY_GOOGLE_API_KEY,
			loadingElement: <div />,
			containerElement: <div />,
			mapElement: <div className={styleSheet.map} />,
		}),
		withHandlers(() => {
			const refs = {
				map: undefined,
			}

			return {
				onMapMounted: () => ref => {
					refs.map = ref;
				},
				setTheBounds: () => (newBounds) => {
					refs.map && refs.map.fitBounds(newBounds);
				}
			}
		}),
		withScriptjs,
		withGoogleMap
	)(props => {

		useEffect(() => {
			if(props.children && props.children.length){
				prepareAndSetBounds();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.children]);

		const prepareAndSetBounds = () => {
			let bounds = new window.google.maps.LatLngBounds();
			props.children.forEach((marker, i) => {
				bounds.extend(new window.google.maps.LatLng(marker.props.position.lat, marker.props.position.lng));
			});
			(props.children.length > 1) && props.setTheBounds(bounds);
		};

		return (
			<GoogleMap
				defaultZoom={8}
				forwardRef="map" 
				center={{ lat: props.coordinates.lat, lng: props.coordinates.lng }}
				defaultOptions={{disableDefaultUI: true}} 
				google={props.google}
				ref={props.onMapMounted} 
			>
				{props.children}
			</GoogleMap>
		)
	}
);*/


const Map = ({ coordinates, children }) => {

	const [map, setMap] = useState(null);

	useEffect(() => {
		if (map) {
			let bounds = new window.google.maps.LatLngBounds();
			children.forEach((marker, i) => {
				bounds.extend(new window.google.maps.LatLng(marker.props.position.lat, marker.props.position.lng));
			});
			(children.length > 1) && map.fitBounds(bounds);
		}
	}, [map, children]);

	const onLoad = useCallback((map) => {
		setMap(map)
	}, []);


	return (
		<LoadScript googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY}>
			<GoogleMap mapContainerClassName={styleSheet.map} center={{ lat: coordinates.lat, lng: coordinates.lng }} zoom={10} onLoad={onLoad}>
				{children}
			</GoogleMap>
		</LoadScript>
	)
};

export default React.memo(Map);